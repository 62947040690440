import { createRouter, createWebHistory } from "vue-router";
// import SplashScreen from "@/views/SplashScreen.vue"
import store from "@/store/index";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { requiresAuth: true },
    component: Home,
    redirect: "/payments",
    children: [
      {
        path: "/requests",
        name: "Tcc",
        component: () => import("../views/Requests.vue"),
      },
      {
        path: "/payments",
        name: "Payments",
        component: () => import("../views/Payments.vue"),
      },
      {
        path: "/audits",
        name: "Audits",
        component: () => import("../views/Audits.vue"),
      },
      {
        path: "/annual-returns",
        name: "annual_returns",
        component: () => import("../views/AnnualReturns.vue"),
      },
      {
        path: "/tpdb/:name?",
        name: "tpdb",
        component: () => import("../views/SearchTaxPayers.vue"),
      },

      {
        path: "/reports",
        name: "reports",
        redirect: "/reports/summary",
        component: () => import("../views/Reports.vue"),
        children: [
          {
            path: "summary",
            name: "ReportSummary",
            component: () =>
              import("../components/report/SummaryDashboard.vue"),
          },
          {
            path: "reconciliation",
            name: "Reconciliation",
            component: () => import("../components/report/Reconciliation.vue"),
          },
        ],
      },
      {
        path: "/assessment",
        name: "Assessment",
        component: () => import("../views/Assessment.vue"),
      },
      {
        path: "/manage_receipts",
        name: "manage_receipts",
        redirect: "/manage_receipts/details",
        component: () => import("../views/ManageReceipts.vue"),
        children: [
          {
            path: "details",
            name: "details",
            component: () =>
              import("../components/receipts/details/IndexPage.vue"),
          },
          {
            path: "uploads",
            name: "uploads",
            component: () =>
              import("../components/receipts/uploads/IndexPage.vue"),
          },
          {
            path: "split",
            name: "split",
            component: () => import("../components/receipts/SplitReceipt.vue"),
          },
          {
            path: "merge",
            name: "merge",
            component: () => import("../components/receipts/MergeReceipt.vue"),
          },
        ],
      },
      {
        path: "/stamp_duty",
        name: "stamp_duty",
        component: () => import("../views/StampDuty.vue"),
      },
      {
        path: "/payer_details",
        name: "payer_details",
        component: () => import("../views/PayerDetails.vue"),
      },
      {
        path: "/property_details",
        name: "property_details",
        component: () => import("../views/PropertyDetails.vue"),
      },
      {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/Settings.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/passwordReset",
    name: "PasswordReset",
    component: () => import("../views/Login.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// let administrators = ["Chairman", "Administrator"]

router.beforeEach((to, from, next) => {
  if (
    to.path == "/login" &&
    (store.getters.getLoggedIn == "true" ||
      store.getters.getLoggedIn == "success")
  )
    next("/");
  else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.getLoggedIn == "false") {
      next({
        path: "/login",
      });
    } else {
      // if (
      //   to.path != "/audits" &&
      //   store.getters.getUnit.toLowerCase() == "chairman's office"
      // ) {
      //   next({ path: "/audits" });
      // } else
      next();
    }
  } else next();
});

export default router;
