<template>
  <div
    class="home relative w-screen h-full flex items-center sm:overflow-hidden"
  >
    <div
      v-click-away="away"
      :class="`z-30 transition-all ease-in-out duration-300 absolute top-0 xl:relative ${
        !state.expanded
          ? 'lg:w-0 -left-full'
          : 'left-0 w-10/12 sm:w-10/12 md:w-5/12 lg:w-4/12 xl:w-1/5 '
      }  h-screen overflow-auto hide-scrollbar  sm:show-scrollbar sm:border-r border-gray-400 bg-gradient-to-b from-blue-800 to-blue-600`"
      :style="`${!state.expanded ? '' : 'min-width: 220px !important'}`"
    >
      <div class="py-2 pr-2 flex flex-row items-center justify-end xl:hidden">
        <button
          class="focus:outline-none focus:ring-1 ring-white rounded-full overflow-hidden outline-none border-none cursor-pointer px-1"
        >
          <i
            @click="navIconClicked"
            ref="bar"
            class="fas fa-times text-gray-100 px-2 py-1 text-2xl"
          ></i>
        </button>
      </div>
      <div class="w-full flex flex-col items-center justify-center py-4">
        <img
          :src="require('../assets/board_logo.png')"
          alt="logo"
          class="h-28 object-cover flex-shrink-0 rounded-full bg-white animate-spin-slow"
          style="width: 7rem !important"
        />
        <p class="mt-2 text-gray-200 font-medium capitalize">
          {{ username }}
        </p>
        <p class="text-gray-200 font-medium capitalize">
          {{ designation }}
        </p>
      </div>
      <div
        class="links flex flex-col text-gray-200 font-medium mb-10"
        @click="toggleDrawer"
      >
        <div v-for="link in links" :key="link.path">
          <router-link
            v-if="link.dropdown.length < 1"
            exact
            class="flex flex-row justify-between items-center pl-6 pr-4 hover:bg-blue-primary-dark border-y-4 rounded-sm focus:shadow-sm border-blue-primary-light focus:bg-blue-primary-dark w-full text-center py-2"
            :to="link.path"
          >
            <div class="flex flex-row items-center">
              <i :class="`${link.icon} text-lg w-10 mr-4 text-left`"></i>
              <span class="text-sm">{{ link.name }}</span>
            </div>
            <div class="flex items-center space-x-2">
              <div
                v-if="link.notification.active && link.notification.counter > 0"
                class="text-xs rounded-full text-gray-100 bg-red-500 shadow-md"
              >
                <span class="p-2 font-bold">
                  {{ link.notification.counter }}
                </span>
              </div>
              <div class="w-6 h-6"></div>
            </div>
          </router-link>
          <div v-else>
            <button
              type="button"
              @click="link.drop = !link.drop"
              class="flex flex-row items-center justify-between pl-6 pr-4 hover:bg-blue-primary-dark border-y-4 rounded-sm focus:shadow-sm border-blue-primary-light focus:bg-blue-primary-dark w-full text-center py-2"
              aria-controls="dropdown-example"
              data-collapse-toggle="dropdown-example"
            >
              <div class="flex items-center justify-center">
                <i :class="`${link.icon} text-lg w-10 mr-4 text-left`"></i>
                <span
                  class="text-left text-sm whitespace-nowrap font-medium"
                  sidebar-toggle-item
                  >{{ link.name }}</span
                >
              </div>

              <div class="flex items-center justify-center space-x-2">
                <div
                  v-if="checkDropDownNotification(link) > 0 && !link.drop"
                  class="text-xs rounded-full text-gray-100 bg-red-500 shadow-md"
                >
                  <span class="p-2 font-bold">
                    {{ checkDropDownNotification(link) }}
                  </span>
                </div>

                <svg
                  sidebar-toggle-item
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </button>
            <ul
              id="dropdown-example"
              :class="[link.drop ? 'block' : 'hidden']"
              class="transition-all duration-200 ease-linear py-2 w-full"
            >
              <li v-for="item in link.dropdown" :key="item.path" class="w-full">
                <router-link
                  exact
                  class="flex flex-row items-center justify-between pr-6 text-sm pl-20 w-full hover:bg-blue-primary-dark border-y-4 rounded-sm focus:shadow-sm border-blue-primary-light focus:bg-blue-primary-dark text-center py-2"
                  :to="`${item.path}`"
                >
                  <div class="flex items-center">
                    <i :class="`${item.icon} text-lg w-10 -mr-2 text-left`"></i>
                    {{ item.name }}
                  </div>
                  <div
                    v-if="
                      item.notification.active && item.notification.counter > 0
                    "
                    class="text-xs rounded-full text-gray-100 bg-red-500 shadow-md"
                  >
                    <span class="p-2 font-bold">
                      {{ item.notification.counter }}
                    </span>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="content"
      :class="`z-10 transition-all ease-in-out duration-300 fixed top-0 left-0 sm:relative ${
        state.expanded ? 'w-full xl:w-4/5 ' : ' w-full '
      }  h-screen `"
    >
      <div
        ref="navbar"
        class="h-14 bg-white w-full px-3 flex items-center justify-between shadow-md"
      >
        <button class="focus:outline-none focus:ring-1 ring-white rounded">
          <i
            @click="navIconClicked"
            ref="bar"
            class="fas fa-bars text-gray-400 px-2 py-1"
          ></i>
        </button>
        <span
          class="text-gray-400 text-xs sm:text-lg font-bold sm:font-medium block sm:hidden"
          >{{ this.scheme }} State BIR</span
        >
        <span
          class="text-gray-400 text-xs sm:text-lg font-bold sm:font-medium hidden sm:block"
          >{{ this.scheme }} State Board of Internal Revenue</span
        >

        <div class="flex">
          <div class="relative text-left">
            <div class="relative">
              <button
                type="button"
                class="inline-flex justify-center w-full bg-white text-sm font-medium text-gray-400 focus:outline-none"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i
                  @click="state.notification = !state.notification"
                  ref="notification"
                  class="fas fa-bell text-md sm:text-lg sm:px-2 py-2"
                ></i>
              </button>

              <div v-if="notifications.length > 0">
                <span
                  class="absolute rounded-full sm:h-2 sm:w-2 w-1.5 h-1.5 bg-blue-500 sm:top-3 top-1.5 left-2 sm:left-5 transform -translate-x-1/2 animate-ping"
                >
                </span>
                <span
                  class="absolute rounded-full sm:h-2 sm:w-2 w-1.5 h-1.5 bg-blue-500 sm:top-3 top-1.5 left-2 sm:left-5 transform -translate-x-1/2"
                >
                </span>
              </div>
            </div>

            <div
              v-if="state.notification && notifications.length > 0"
              class="z-50 animate__animated animate__zoomIn animate__faster origin-top-right absolute right-0 mt-2 w-44 sm:w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <div
                v-for="item in notifications"
                @click="
                  $router.replace(item.path);
                  state.notification = !state.notification;
                "
                :key="item.name"
                class="text-xs px-4 py-2 border-b border-gray-200 text-gray-500 font-medium cursor-pointer hover:bg-gray-200"
              >
                <p>
                  You have
                  <span class="font-bold text-md">{{ item.count }}</span>
                  pending <span class="font-bold text-md">{{ item.name }}</span>
                  notification(s).
                </p>
              </div>
            </div>
            <div
              v-else-if="state.notification && notifications.length < 1"
              class="z-50 animate__animated animate__zoomIn animate__faster origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <div
                class="text-xs px-4 py-2 border-b border-gray-200 text-gray-500 font-medium cursor-pointer hover:bg-gray-200"
              >
                <p>You have no pending notifications.</p>
              </div>
            </div>
          </div>
          <div class="relative text-left">
            <div>
              <button
                type="button"
                class="inline-flex justify-center w-full bg-white text-sm font-medium text-gray-400 focus:outline-none"
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i
                  @click="state.menu = !state.menu"
                  ref="more_vert"
                  class="fas fa-ellipsis-v text-md sm:text-lg px-2 py-2"
                ></i>
              </button>
            </div>

            <div
              v-if="state.menu"
              class="z-50 animate__animated animate__zoomIn animate__faster origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <div class="py-1">
                <button
                  ref="btnRefresh"
                  id="btnRefresh"
                  @click="refreshPage"
                  class="nav_option block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  Refresh
                </button>
                <button
                  ref="btnVerify"
                  id="btnVerify"
                  @click="gotoExternalURL('https://etax.bir.by.gov.ng/#verify')"
                  target="__blank"
                  class="nav_option block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  Verify payment
                </button>
                <button
                  ref="btnEtax"
                  id="btnEtax"
                  @click="gotoRoute('Settings')"
                  class="nav_option block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  Settings
                </button>
                <button
                  ref="btnLogout"
                  id="btnLogout"
                  @click="logout()"
                  class="nav_option block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  Sign out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section :class="`main-h overflow-x-hidden ${shouldScroll()}`">
        <slot></slot>
      </section>
    </div>
  </div>
</template>

<script>
// import { mixin as clickaway } from "vue-clickaway";
import { mapActions } from "vuex";
import { mda } from "../helpers/login_details";
// @ is an alias to /src
import { emitter } from "@/main.js";
import { ref, watchEffect, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  // mixins: [clickaway],
  name: "Drawer",
  props: {
    links: {
      type: Array,
      required: true,
    },
    notifications: {
      type: Array,
    },
    username: { type: String },
    designation: { type: String },
  },

  setup() {
    const bar = ref(null);
    const more_vert = ref(null);
    const btnProfile = ref(null);
    const btnLogout = ref(null);
    const hideScroll = ref(false);
    const route = useRoute();
    const router = useRouter();
    const path = ref(null);

    watchEffect(() => {
      path.value = route.path;
    });

    function refreshPage() {
      //    if (path.value === "/requests") {
      router.go();
      //    }
    }
    function shouldScroll() {
      if (
        path.value === "/audits" ||
        path.value === "/assessment" ||
        hideScroll.value
      )
        return "overflow-y-hidden";

      return "overflow-y-auto  ";
    }

    onMounted(() => {
      emitter.on("hide_scroll", (val) => (hideScroll.value = val));
    });

    return {
      bar,
      more_vert,
      btnLogout,
      btnProfile,
      refreshPage,
      shouldScroll,
      path,
    };
  },
  data: () => ({
    state: {
      expanded: true,
      menu: false,
      notification: false,
      scheme: "",
      notifications: [],
    },
  }),
  watch: {
    $route() {
      if (screen.width < 1280) this.state.expanded = false;

      emitter.emit("drawer", this.state.expanded);
    },
    "state.notification"(val) {
      if (this.state.menu && val) this.state.menu = false;
    },
    "state.menu"(val) {
      if (this.state.notification && val) this.state.notification = false;
    },
  },
  methods: {
    ...mapActions(["logout"]),
    checkDropDownNotification(link) {
      let count = 0;
      for (let index = 0; index < link.dropdown.length; index++) {
        if (link.dropdown[index].notification.active) {
          count += link.dropdown[index].notification.counter;
        }
      }

      return count;
    },
    toggleMenu() {
      this.state.menu = !this.state.menu;
    },
    gotoExternalURL(url) {
      this.state.menu = false;
      window.location.href = url;
    },
    gotoRoute(route) {
      this.$router.replace({ name: route }).catch((err) => {
        console.log(err);
      });
      this.state.menu = false;
    },
    toggleDrawer() {
      // console.log('drawer clicked');
      // if (screen.width < 800) this.state.expanded = false;
      // emitter.emit("drawer", this.state.expanded);
    },
    navIconClicked() {
      this.state.expanded = !this.state.expanded;
      emitter.emit("drawer", !this.state.expanded);
    },
    away(e) {
      if (e.srcElement != this.$refs.bar && screen.width < 1280)
        this.state.expanded = false;
      if (
        e.srcElement != this.$refs.more_vert &&
        e.srcElement != this.$refs.notification &&
        e.srcElement != this.$refs.btnLogout &&
        e.srcElement != this.$refs.btnProfile &&
        e.srcElement != this.$refs.btnRefresh &&
        e.srcElement != this.$refs.btnEtax &&
        e.srcElement != this.$refs.btnVerify
      )
        this.state.menu = false;

      // if (e.srcElement != this.$refs.notification) {
      //   this.state.notification = false;
      // }

      // if(e.srcElement != more_vert.value && (e.srcElement != btnLogout.value )) state.menu = false;
    },
  },
  created() {
    if (screen.width < 800) this.state.expanded = false;

    this.scheme = localStorage.getItem("RH_scheme") || "";

    setTimeout(() => {
      if (!mda) {
        this.$store.dispatch("logout");
        // window.location.reload();
      }
    }, 1100);
  },
};
</script>

<style lang="scss">
.router-link-active {
  border-left: 4px solid #26a7e8 !important;
  background-color: #1d4894 !important;
}

.capitalize {
  text-transform: capitalize !important;
}
</style>
