/* eslint-disable no-unused-vars */
import { createStore } from "vuex";
import router from "@/router/index";
import { cookies } from "@/helpers/cookie";
import { STORAGE_ITEM_NAME } from "@/helpers/keys";
import { Axios } from "../helpers/axios-config";
import assessment from "./assessment";
import report from "./report";
import stamp_duty from "@/store/stamp_duty";
import annual_returns from "@/store/annual_returns";
import invoice from "@/store/invoice";
import taxpayerInfo from "./taxpayer_info";
import dayjs from "dayjs";

import { getAllPayers, getPayer } from "../helpers/idb";

// function filterResult(items, criteria) {
//     if (items && items.length > 0) {
//         return items.filter(item => {
//             return item.customer_name.toLowerCase().indexOf(criteria.toLowerCase()) > -1
//         });
//     }
// }

function generateEndpointFromFilter(filter, isFetchItems) {
  // "Revenue Item", "Revenue Category", "Bank";
  if (!isFetchItems) {
    if (filter === "Revenue Item") return "revenueItems";
    if (filter === "Revenue Category") return "revenueCategories";
    if (filter === "Bank") return "banks";
  }
  if (filter === "Revenue Item") return "paymentsByRevenueItem";
  if (filter === "Revenue Category") return "paymentsByRevenueCategory";
  if (filter === "Bank") return "paymentsByBank";
  if (filter === "Payment Channels") return "paymentsByFilter";
}

export default createStore({
  modules: {
    assessment,
    taxpayerInfo,
    stamp_duty,
    report,
    annual_returns,
    invoice,
  },
  state: {
    loggedIn: cookies.getLoginStateFromCookie(),
    unit: cookies.getUnitFromCookie(),
    years: [],
    request_count: 0,
    company_requests: [],
    company_request_singles: [],
    taxPayers: [],
    payments: [],
    payment_total: {},
    top_payers: [],
    companies: [],
    companies_count: 0,
    audit_companies: [],
    audit_requests: [],
  },
  getters: {
    getLoggedIn: (state) => state.loggedIn,
    getUnit: (state) => state.unit,
    getYears: (state) => state.years,
    getCompanyRequests: (state) => state.company_requests,
    getRequestCount: (state) => state.request_count,
    getCompanyRequestSingles: (state) => state.company_request_singles,
    getPaymentTotal: (state) => state.payment_total,
    getTopPayers: (state) => state.top_payers,
    getAuditCompanies: (state) => state.audit_companies,
    getAuditRequests: (state) => state.audit_requests,
    getCompanies: (state) => state.companies,
    getCompaniesCount: (state) => state.companies_count,
    getAllPayments: (state) => state.payments,
  },
  mutations: {
    LOGGED_IN(state, payload) {
      state.loggedIn = payload.state;

      if (payload.loginDetails) {
        state.unit = payload.loginDetails.unit;
        // Axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}+'jhg'`;
      }

      cookies.setCookie(STORAGE_ITEM_NAME, JSON.stringify(payload), 365);
    },
    searchPayer(state, payload) {
      state.taxPayers = payload;
    },
    REQUEST_YEARS(state, payload) {
      state.years = payload;
    },
    COMPANY_REQUESTS(state, payload) {
      state.request_count = 0;
      if (payload.length > 0)
        payload.forEach((r) => {
          state.request_count += r.count;
        });
      state.company_requests = payload;
    },
    COMPANY_REQUEST_SINGLES(state, payload) {
      state.company_request_singles = payload;
    },
    RESET_REQUEST_SINGLES(state, payload) {
      state.company_request_singles = payload;
    },
    REDUCE_REQUEST_SINGLES(state, payload) {
      let reqs = state.company_request_singles.filter((r) => {
        return r.db_certificate_id != payload;
      });
      state.company_request_singles = reqs;
    },

    REDUCE_REQUESTS(state, payload) {
      let reqs = state.company_requests.filter((r) => {
        return !(
          r.org_name.includes(payload.title) && r.request_year == payload.year
        );
      });

      state.company_requests = reqs;
      state.request_count = 0;
      if (state.company_requests.length > 0)
        state.company_requests.forEach((r) => {
          state.request_count += r.count;
        });
    },
    REQUEST_COUNT(state, payload) {
      state.request_count = payload;
    },
    //Payments
    PAYMENT_TOTAL(state, payload) {
      state.payment_total = payload;
    },
    TOP_PAYERS(state, payload) {
      state.top_payers = payload;
    },
    COMPANIES_COUNT(state, payload) {
      state.companies_count = payload;
    },
    AUDIT_COMPANIES(state, payload) {
      state.audit_companies = payload;
    },
    AUDIT_REQUESTS(state, payload) {
      state.audit_requests = payload;
    },
    COMPANIES(state, payload) {
      state.companies = payload;
    },
    PAYMENTS(state, payload) {
      state.payments = payload;
    },

    REDUCE_COMPANIES(state, payload) {
      let companies = state.companies.filter((r) => {
        return r.company_id != payload;
      });
      state.companies = companies;
    },
    REDUCE_AUDITS(state, payload) {
      // console.log(payload);
      if (payload > 0) {
        let audits = state.audit_requests.filter((r) => {
          return r.id != payload;
        });
        state.audit_requests = audits;
      } else state.audit_requests = [];
    },
  },
  actions: {
    getRequestYears({ commit }) {
      Axios.get("fetchYears").then((result) => {
        // console.log(result);
        commit("REQUEST_YEARS", result.data);
      });
    },
    getRequests({ commit }) {
      return new Promise((res, rej) => {
        Axios.get("companyRequests")
          .then((result) => {
            commit("COMPANY_REQUESTS", result.data);
            res("success");
          })
          .catch((err) => rej(err.message));
      });
    },
    getRequestSingles({ commit }, payload) {
      // console.log(payload);
      return new Promise((res, rej) => {
        Axios.post("companyEmpRequests", payload)
          .then((result) => {
            commit("COMPANY_REQUEST_SINGLES", result.data);
            res(`success ${result.data.length}`);
          })
          .catch((err) => rej(err.message));
      });
    },
    reduceRequestSingles({ commit }, payload) {
      commit("REDUCE_REQUEST_SINGLES", payload);
      // commit("REQUEST_COUNT", 0);
    },
    reduceRequests({ commit }, payload) {
      commit("REDUCE_REQUESTS", payload);
    },
    updateRequestCount({ commit }, payload) {
      commit("REQUEST_COUNT", payload);
    },
    // eslint-disable-next-line no-unused-vars
    authorizeById({ _ }, payload) {
      return new Promise((res, rej) => {
        Axios.post("authorizeById", payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => rej(err.message));
      });
    },
    // eslint-disable-next-line no-unused-vars
    authorizeAll({ _ }, payload) {
      // console.log(payload);
      return new Promise((res, rej) => {
        Axios.post("authorizeRequestsByOrg", payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => rej(err.message));
      });
    },
    resetRequestSingles({ commit }) {
      commit("COMPANY_REQUEST_SINGLES", []);
    },

    //Payments
    // eslint-disable-next-line no-unused-vars
    getPaymentTotalByFilter({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("totalPaymentByFilter", payload)
          .then((result) => {
            // console.log(result.data[0].Amount);
            commit("PAYMENT_TOTAL", result.data[0]);
            res("success");
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    }, // eslint-disable-next-line no-unused-vars
    getTopPayersByFilter({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("topPayers", payload)
          .then((result) => {
            commit("TOP_PAYERS", result.data);
            res("success");
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getPayments({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("paymentsByFilter", payload)
          .then((result) => {
            commit("PAYMENTS", result.data);
            res("success");
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getPaymentDetails({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("payment/verify", payload)
          .then((result) => {
            res(result.data);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getPaymentReceipts({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("payment/updateRequests", payload)
          .then((result) => {
            res(result.data);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getPaymentsForApproval({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("payments/forApproval", payload)
          .then((result) => {
            res(result.data);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    deletePaymentReceipt({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.delete("payment/updateRequests", {
          data: {
            ...payload,
          },
        })
          .then((result) => {
            res(result.data);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    deletePaymentUploads({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.delete("payments/delete", {
          data: payload,
        })
          .then((result) => {
            res(result.data);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    editPaymentReceipt({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.put("payment/updateRequests", payload)
          .then((result) => {
            res(result.data);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    editPaymentUpload({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.put("payments/update/" + payload.id, payload)
          .then((result) => {
            res(result.data);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    uploadBatchPayment({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("payments/create/bulk", payload)
          .then((result) => {
            res(result);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    uploadPaymentDetails({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("payments/create", payload)
          .then((result) => {
            res(result);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    updatePaymentDetails({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("payment/updateRequests/new", payload)
          .then((result) => {
            res(result);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    authorizePaymentDetailsUpdate({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.put("payment/updateRequests/approval", payload)
          .then((result) => {
            res(result);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    authorizePaymentDetailsUpload({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.put("payments/approve", payload)
          .then((result) => {
            res(result);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getReconciliationData({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("paymentsByFilter", payload)
          .then((result) => {
            res(result.data);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },

    //Audits
    // eslint-disable-next-line no-unused-vars
    createNewCompany({ _ }, payload) {
      return new Promise((res, rej) => {
        Axios.post("company", payload)
          .then((result) => {
            // console.log(result);
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => rej(err.message));
      });
    },
    getCompanyList({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("companies", payload)
          .then((result) => {
            const { status, message, data } = result;

            if (status !== "error") {
              commit("COMPANIES", result.data[0]);
              commit("COMPANIES_COUNT", result.data[0].length);
              res("success");
            }

            rej(message);
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getCompaniesListForAudit({ commit }) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.get("companies")
          .then((result) => {
            const { status, message, msg, data } = result.data;
            if (status.toLowerCase() === "success") {
              res(data);
            } else {
              rej(message || msg);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getCompaniesListForAudit2({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((res, rej) => {
        Axios.post("companies", payload)
          .then((result) => {
            commit("AUDIT_COMPANIES", result.data[0]);
            // commit("COMPANIES_COUNT", result.data[1][0].totalCount);
            res("success");
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getAllAuditRequests({ commit }, payload) {
      // eslint-disable-next-line no-unused-vars
      // console.log(payload);
      return new Promise((res, rej) => {
        Axios.post("auditsByStatus", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              commit("AUDIT_REQUESTS", data);
              res(message);
            } else {
              rej(message);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    getAllAuditRequests2({ __ }, payload) {
      // eslint-disable-next-line no-unused-vars
      // console.log(payload);
      return new Promise((res, rej) => {
        Axios.post("auditsByStatus", payload)
          .then((result) => {
            const { status, message, data } = result.data;
            if (status.toLowerCase() === "success") {
              res(data);
            } else {
              rej(message);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    authorizeSingleAudit({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.post("approveAudit", payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REDUCE_AUDITS", payload.ids[0]);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    authorizeAllAudits({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.post("approveAudit", payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REDUCE_AUDITS", 0);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    alterAuditCompanies({ commit }, payload) {
      // console.log(result);
      commit("AUDIT_COMPANIES", payload);
    },
    sendAuditRequests({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.post("audit", payload)
          .then((result) => {
            // console.log(result);
            const { status, message, msg } = result.data;
            if (status.toLowerCase() === "success") {
              commit("AUDIT_COMPANIES", []);
              res(message || msg);
            } else {
              rej(status + ` ${message || msg}`);
            }
          })
          .catch((err) => rej(err.message));
      });
    },
    singleAuditPrintDone({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.put("setPrintedAudit", payload)
          .then((result) => {
            // console.log(result);
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REDUCE_AUDITS", payload.ids[0]);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => rej(err.message));
      });
    },
    allAuditPrintDone({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.put("setPrintedAudit", payload)
          .then((result) => {
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              payload.ids.forEach((id) => {
                commit("REDUCE_AUDITS", id);
              });
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    deleteCompany({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.delete(`company/${payload}`)
          .then((result) => {
            // console.log(result);
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("REDUCE_COMPANIES", payload);
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    // eslint-disable-next-line no-unused-vars
    updateCompany({ commit }, payload) {
      return new Promise((res, rej) => {
        Axios.put(`company/${payload.id}`, payload.data)
          .then((result) => {
            // console.log(result);
            const { status, message } = result.data;
            if (status.toLowerCase() === "success") {
              res(message);
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },

    login({ commit }, payload) {
      // console.log("Axios Request", payload)
      return new Promise((res, rej) => {
        Axios.post(`login`, payload)
          .then((result) => {
            // console.log(result);
            const { data, status, message } = result.data;
            if (status.toLowerCase() === "success") {
              commit("LOGGED_IN", { status, ...data });
              // eslint-disable-page no-unused-vars

              res("success");
            } else {
              rej(status + ` ${message}`);
            }
          })
          .catch((err) => {
            rej(err.message);
          });
      });
    },
    updatePassword({ commit }, payload) {
      // console.log("Axios Request", payload)
      return new Promise((res, rej) => {
        Axios.post(`admin/changePassword`, payload)
          .then((result) => {
            console.log(result);
            const { data, status, message, msg } = result.data;
            if (status.toLowerCase() === "success") {
              res("success");
            } else {
              rej(status + ` ${message || msg}`);
            }
          })
          .catch((err) => {
            console.log(err.message);
            rej(err.message);
          });
      });
    },
    logout({ commit }) {
      commit("LOGGED_IN", { status: "false", data: {} });
      // eslint-disable-next-line no-unused-vars

      router.replace({ path: "/login" }).catch(() => {});
      console.log("logging out");
    },
    async fetchItemsToFilter({ commit }, payload) {
      // payload.reportType = 'payment';
      // console.log(payload);
      const endpoint = generateEndpointFromFilter(payload, false);
      const result = await Axios.get(`${endpoint}`);

      return result.data;
    },
    async fetchItemsByFilter({ commit }, payload) {
      payload.reportType = "payment";
      const endpoint = generateEndpointFromFilter(payload.filter, true);

      payload.startDate = dayjs(payload.startDate).format("YYYY-MM-DD");
      payload.endDate = dayjs(payload.endDate).format("YYYY-MM-DD");

      if (payload.filter === "Payment Channels") {
        payload.limit = 1000;
        payload.page = 1;
        payload.channel = payload.item;
        delete payload.item;
      }
      if (payload.filter) delete payload.filter;

      const result = await Axios.post(`${endpoint}`, payload);

      if (result.data.result) return result.data.result;
      else return result.data.data;
    },

    async searchPayer({ commit }, payload) {
      if (payload.isStale) {
        const res = await getAllPayers();
        if (res.length > 0) {
          let result = await getPayer(payload.criteria);
        }
      } else {
        try {
          const result = await Axios.post("tpdb", { payer: payload.criteria });
          const { data } = result.data;
          commit("searchPayer", data);
          return data;
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
});
